import mockup from "../images/iphone_mockup.png";
import integrated_storytelling from "../images/integrated_storytelling.svg";
import community_voting from "../images/community_voting.svg";
import archive from "../images/archive.svg";
import social_style from "../images/social_style.svg";

const features = [
  {
    name: "Integrated storytelling:",
    description:
      "Tale allows users to create a story starting from an opening provided by another user and develop it together with the community. Each contribution adds a new piece to the plot, creating a unique and engaging story.",
    icon: integrated_storytelling,
  },
  {
    name: "Community voting:",
    description:
      "During a dedicated time slot, the community has the opportunity to vote on which contribution to add next to the story. This process ensures active user engagement and promotes the creation of high-quality stories.",
    icon: community_voting,
  },
  {
    name: "Complete story archive:",
    description:
      "Completed stories are accessible to all users and are organized by categories, allowing users to explore a wide range of narratives and discover new authors and genres.",
    icon: archive,
  },
  {
    name: "Social network style:",
    description:
      "Tale is more than just a storytelling platform; it’s a true social network dedicated to creativity and imagination. Users can interact with stories through likes, comments, and shares, making the reading experience more engaging and rewarding.",
    icon: social_style,
  },
];

export default function ProductComponent() {
  return (
    <div id="product" className="mt-24 md:mt-48 overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-palette-primary text-3xl font-bold">
            The platform
          </h2>
          <hr className="bg-palette-primary mt-2 h-0.5 w-1/2 sm:w-1/4 mx-auto"></hr>
          <p className="mt-12 text-2xl md:text-3xl leading-8 font-bold text-gray-900">
            In Tale, we envision a future where storytelling becomes a powerful
            tool to connect people from all around the world.
          </p>
        </div>
        <div className="mx-auto mt-16 md:mt-32 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold text-left tracking-tight text-palette-primary sm:text-4xl">
                An innovative social network
              </p>
              <p className="mt-6 text-lg text-left font-medium leading-8">
                Tale combines group storytelling with the style of a social
                network, allowing the creation of a strong community from all
                around the world.
              </p>
              <ul className="mt-10 max-w-xl space-y-8 text-base text-left leading-7 lg:max-w-none">
                {features.map((feature) => (
                  <li key={feature.name} className="flex gap-x-3">
                    <img
                      className="mt-1 h-[50px] flex-none text-indigo-600"
                      aria-hidden="true"
                      src={feature.icon}
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        {feature.name}
                      </strong>{" "}
                      {feature.description}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <img
            src={mockup}
            alt="Product screenshot"
            className="w-[28rem] mx-auto max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
