import tale_logo from "../images/tale_logo.svg";
export default function FooterComponent() {
  return (
    <footer
      id="footer"
      className="mx-auto w-full max-w-container px-4 sm:px-6 lg:px-8"
    >
      <div className="border-t border-slate-900/5 py-10">
        <img className="w-[60px] mx-auto" src={tale_logo} />
        <p className="mt-5 text-center text-sm leading-6 text-slate-500">
          © 2025 Tale. All rights reserved.
        </p>
        <div className="mt-8 flex items-center justify-center space-x-4 text-sm font-semibold leading-6 text-slate-700">
          <a href="mailto:tale@gowebb.it">tale@gowebb.it</a>
          <div className="h-4 w-px bg-slate-500/20"></div>
          <a href="/privacy-policy" className="hover:text-pink-primary">
            Privacy Policy
          </a>
          <div className="h-4 w-px bg-slate-500/20"></div>

          <a href="/terms-and-conditions" className="hover:text-pink-primary">
            Terms & Conditions
          </a>
          <div className="h-4 w-px bg-slate-500/20"></div>

          <a href="/eula" className="hover:text-pink-primary">
            EULA
          </a>
        </div>
      </div>
    </footer>
  );
}
