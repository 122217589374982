import AboutComponent from "../components/AboutComponent";
import DownloadComponent from "../components/DownloadComponent";
import FooterComponent from "../components/FooterComponent";
import HeaderComponent from "../components/HeaderComponent";
import HeroComponent from "../components/HeroComponent";
import ProductComponent from "../components/ProductComponent";

const LandingPage = () => {
  return (
    <div className="flex flex-col">
      <HeaderComponent />
      <HeroComponent />
      <AboutComponent />
      <ProductComponent />
      <DownloadComponent />
      <FooterComponent />
    </div>
  );
};

export default LandingPage;
