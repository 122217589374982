import mission_illustration from "../images/mission_illustration.png";
import vision_illustration from "../images/vision_illustration.jpg";

export default function AboutComponent() {
  return (
    <section id="about" className="mt-24 md:mt-48 max-w-7xl xl:w-1200 m-auto">
      <div className="space-y-32">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-palette-primary text-3xl font-bold">About us</h2>
          <hr className="bg-palette-primary mt-2 h-0.5 w-1/2 sm:w-1/4 mx-auto"></hr>
          <p className="mt-12 text-2xl md:text-3xl leading-8 font-bold text-gray-900">
            With Tale, we aim to provide users with a space where they can start
            a story and watch it grow through the contributions of the
            community.
          </p>
        </div>
      </div>
      <div className="space-y-8 px-8 md:space-y-0 text-center md:text-left md:space-x-16 md:justify-center md:flex md:items-center ">
        <div className="w-full mt-24 md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-y-24">
          <div className="my-auto">
            <h3 className="font-bold text-2xl">Our mission</h3>
            <p className="mt-2 font-medium">
              We want to inspire and connect people through the sharing of
              stories, encouraging creativity and imagination in an inclusive
              and fun environment, using the features of a social network.
            </p>
          </div>

          <div className="mx-auto my-auto w-1/2 md:w-1/2">
            <img
              className="md:w-80 mx-auto my-auto"
              src={mission_illustration}
            />
          </div>

          <div className="mx-auto my-auto w-1/2 md:w-1/2 order-last md:order-none">
            <img className="md:w-80 mx-auto" src={vision_illustration} />
          </div>
          <div className="my-auto">
            <h3 className="font-bold text-2xl">Our vision</h3>
            <p className="mt-2 font-medium">
              We believe in a world where storytelling becomes a shared
              experience, where people come together to build unique and
              engaging stories. We want to give everyone a voice, allowing them
              to express themselves freely and discover new stories in a
              welcoming and inspiring environment.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
