const EndUserLicenseAgreement = () => {
  return (
    <div className="py-16 text-gray-800 sm:py-24">
      <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
        <h1 className="mb-6 border-b border-gray-700 pb-4 text-4xl font-bold text-gray-900">
          END USER LICENSE AGREEMENT (EULA) FOR TALE APP
        </h1>

        <p className="mb-8 text-sm text-gray-600">
          Last Updated: April 2, 2025
        </p>

        <div className="prose prose-invert max-w-none">
          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            INTRODUCTION
          </h2>

          <p className="mb-4">
            This End User License Agreement ("EULA" or "Agreement") is a binding
            legal agreement between you ("End User," "User," or "you") and
            GoWebb di Nicola Baccillieri ("Service Provider," "we," or "us")
            regarding your use of the Tale application ("Application" or
            "Tale"), including all related documentation.
          </p>

          <p className="mb-4">
            By downloading, installing, accessing, or using the Application, you
            agree to be bound by the terms of this EULA. If you do not agree to
            these terms, do not download, install, access, or use the
            Application.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            AGE RESTRICTIONS
          </h2>

          <p className="mb-4 font-bold">
            THIS APPLICATION IS STRICTLY INTENDED FOR USERS 17 YEARS OF AGE OR
            OLDER.
          </p>

          <p className="mb-4">
            By downloading, installing, accessing, or using the Application, you
            represent and warrant that you are at least 17 years of age. If you
            are under 17 years of age, you are not permitted to use the
            Application under any circumstances.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            ZERO TOLERANCE POLICY FOR OBJECTIONABLE CONTENT AND ABUSIVE BEHAVIOR
          </h2>

          <p className="mb-4">
            The Service Provider maintains a strict zero-tolerance policy
            regarding objectionable content and abusive behavior. You agree not
            to use the Application to:
          </p>

          <p className="mb-4 font-semibold">
            1. Create, upload, post, share, or distribute any content that is:
          </p>

          <ul className="mb-8 list-disc space-y-4 pl-6">
            <li>Sexually explicit or pornographic</li>
            <li>Excessively violent or glorifies violence</li>
            <li>Harassing, bullying, or threatening</li>
            <li>Defamatory or fraudulent</li>
            <li>
              Promoting hate speech, discrimination, or intolerance based on
              race, ethnicity, gender, religion, sexual orientation, disability,
              or any other protected characteristic
            </li>
            <li>Illegal or promoting illegal activities</li>
            <li>Infringing on intellectual property rights of others</li>
            <li>Invasive of another's privacy</li>
          </ul>

          <p className="mb-4 font-semibold">
            2. Engage in any abusive behavior, including but not limited to:
          </p>

          <ul className="mb-8 list-disc space-y-4 pl-6">
            <li>Harassment or intimidation of other users</li>
            <li>
              Impersonation of another user, the Service Provider, or any other
              person
            </li>
            <li>Spamming or flooding</li>
            <li>Attempting to manipulate community voting</li>
            <li>
              Using the platform to send unsolicited messages or advertisements
            </li>
            <li>Exploiting, abusing, or harming minors in any way</li>
            <li>Attempting to circumvent any content filtering mechanisms</li>
          </ul>

          <p className="mb-4">
            Any violation of this policy may result in immediate termination of
            your account, removal of your content, and potential legal action
            when applicable. The Service Provider reserves the right to report
            illegal activities to appropriate law enforcement authorities.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            LICENSE GRANT AND RESTRICTIONS
          </h2>

          <p className="mb-4">
            Subject to your compliance with this EULA, the Service Provider
            grants you a limited, non-exclusive, non-transferable, revocable
            license to download, install, and use the Application for your
            personal, non-commercial purposes on a mobile device that you own or
            control.
          </p>

          <p className="mb-4">You may not:</p>

          <ul className="mb-8 list-disc space-y-4 pl-6">
            <li>
              Use the Application for any illegal purpose, or in violation of
              any local, state, national, or international law
            </li>
            <li>
              Copy, modify, adapt, translate, create derivative works, reverse
              engineer, decompile, or disassemble any portion of the Application
            </li>
            <li>
              Remove, alter, or obscure any copyright, trademark, or other
              proprietary rights notice on the Application
            </li>
            <li>
              Use the Application in any manner that could damage, disable,
              overburden, or impair the Application or interfere with any other
              party's use of the Application
            </li>
            <li>
              Use any robot, spider, site search/retrieval application, or other
              automated device, process, or means to access, retrieve, scrape,
              or index any portion of the Application
            </li>
            <li>Sell, rent, lease, or sublicense the Application</li>
          </ul>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            USER CONTENT
          </h2>

          <p className="mb-4">
            The Service Provider does not claim ownership of content that you
            submit or make available through the Application ("User Content").
            However, by submitting User Content, you grant the Service Provider
            a worldwide, royalty-free, non-exclusive license to use, distribute,
            reproduce, modify, adapt, publish, translate, and display such User
            Content in connection with providing and promoting the Application.
          </p>

          <p className="mb-4">You represent and warrant that:</p>

          <ul className="mb-8 list-disc space-y-4 pl-6">
            <li>
              You own or have obtained all necessary rights to the User Content
            </li>
            <li>
              The User Content does not and will not violate any third-party
              rights
            </li>
            <li>
              The User Content complies with this EULA and all applicable laws
            </li>
          </ul>

          <p className="mb-4">
            The Service Provider reserves the right, but is not obligated, to
            monitor, edit, or remove any User Content at our sole discretion.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            CONTENT MODERATION
          </h2>

          <p className="mb-4">
            The Service Provider employs various content moderation techniques,
            including but not limited to algorithmic filtering, user reporting,
            and human review. While we strive to enforce our content policies
            effectively, we cannot guarantee that all objectionable content will
            be identified and removed immediately. You are encouraged to report
            any content that violates our policies.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            THIRD-PARTY SERVICES
          </h2>

          <p className="mb-4">
            The Application may display, include, or make available third-party
            content or provide links to third-party websites or services. The
            Service Provider does not control, endorse, or assume responsibility
            for any third-party content or services. Any dealings you have with
            third parties while using the Application are between you and the
            third party, and you agree that the Service Provider is not liable
            for any loss or claim that you may have against any such third
            party.
          </p>

          <p className="mb-4">
            The Application utilizes the following third-party services that
            have their own Terms and Conditions:
          </p>

          <ul className="mb-8 list-disc space-y-4 pl-6">
            <li>
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline hover:text-blue-300"
              >
                Google Play Services
              </a>
            </li>
            <li>
              <a
                href="https://www.google.com/analytics/terms/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline hover:text-blue-300"
              >
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a
                href="https://firebase.google.com/terms/crashlytics"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 underline hover:text-blue-300"
              >
                Firebase Crashlytics
              </a>
            </li>
          </ul>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            PRIVACY
          </h2>

          <p className="mb-4">
            The Service Provider's Privacy Policy, available within the
            Application, explains how we collect, use, and disclose information
            from our users. By using the Application, you agree to the
            collection, use, and disclosure of information as described in the
            Privacy Policy.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            TERMINATION
          </h2>

          <p className="mb-4">
            This EULA is effective until terminated by you or the Service
            Provider. You may terminate this EULA at any time by permanently
            deleting the Application from your device.
          </p>

          <p className="mb-4">
            The Service Provider may terminate this EULA at any time without
            notice if you fail to comply with any of its terms. Upon
            termination, you must cease all use of the Application and delete it
            from your device.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            DISCLAIMER OF WARRANTIES
          </h2>

          <p className="mb-4">
            THE APPLICATION IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
            WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. THE SERVICE
            PROVIDER DOES NOT WARRANT THAT THE APPLICATION WILL FUNCTION
            UNINTERRUPTED, SECURE, OR AVAILABLE AT ANY PARTICULAR TIME OR
            LOCATION, OR THAT ANY ERRORS OR DEFECTS WILL BE CORRECTED.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            LIMITATION OF LIABILITY
          </h2>

          <p className="mb-4">
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            THE SERVICE PROVIDER BE LIABLE FOR ANY INDIRECT, PUNITIVE,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES, OR ANY DAMAGES
            WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
            DATA, OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
            USE OR PERFORMANCE OF THE APPLICATION.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            CHANGES TO THIS EULA
          </h2>

          <p className="mb-4">
            The Service Provider reserves the right to modify this EULA at any
            time. If we make material changes to this EULA, we will notify you
            by prominently posting the revised EULA in the Application or by
            other means. Your continued use of the Application after such
            changes constitutes your acceptance of the new terms.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            GOVERNING LAW
          </h2>

          <p className="mb-4">
            This EULA shall be governed by and construed in accordance with the
            laws of Italy, without regard to its conflict of law provisions.
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            CONTACT INFORMATION
          </h2>

          <p className="mb-4">
            If you have any questions about this EULA, please contact the
            Service Provider at{" "}
            <a
              href="mailto:tale@gowebb.it"
              className="text-blue-400 underline hover:text-blue-300"
            >
              tale@gowebb.it
            </a>
            .
          </p>

          <h2 className="mt-12 mb-6 border-b border-gray-700 pb-3 text-3xl font-semibold text-gray-900">
            ACKNOWLEDGMENT
          </h2>

          <p className="mb-4">
            BY DOWNLOADING, INSTALLING, ACCESSING, OR USING THE APPLICATION, YOU
            ACKNOWLEDGE THAT YOU HAVE READ THIS EULA, UNDERSTAND IT, AND AGREE
            TO BE BOUND BY ITS TERMS AND CONDITIONS.
          </p>

          <hr className="my-8 border-gray-700" />

          <p className="mb-4 text-sm text-gray-600">
            Effective Date: April 2, 2025
          </p>
        </div>
      </div>
    </div>
  );
};

export default EndUserLicenseAgreement;
